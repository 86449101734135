
import Vue from "vue";

import http from "@/http";
import { Snackbar } from "@/interfaces";

export interface DataType {}

export default Vue.extend({
  components: {},
  data(): DataType {
    return {};
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "修理",
          disabled: false,
          to: "/repairs"
        },
        {
          text: "アップロード",
          disabled: true,
          to: "/repair/create"
        }
      ];
    }
  },
  watch: {
    async $route() {}
  },
  methods: {
    async change(files: File[]) {
      const formData = new FormData();

      if (!files) {
        return;
      }

      const file = files[0];

      formData.append("file", file);

      const headers = {
        "Content-Type": "multipart/form-data"
      };

      try {
        const res = await http.post("/repairs", formData, {
          headers
        });

        await this.$router.push(`/repair/${res.data.id}`);
      } catch (error) {
        const snackbar: Snackbar = {
          show: true,
          content: "ファイルに不備があるようです",
          color: "success"
        };

        this.$store.commit("setSnackbar", snackbar);
      }
    }
  }
});
